<template>
	<div id="login">
		<v-head></v-head>
		<div class="tabbar">
			<router-link to="/login" :class="{ 'current': isLogin }">登錄</router-link>
			<router-link to="/register" :class="{ 'current': !isLogin }">註冊</router-link>
		</div>
		<div class="form">
			<div class="form-item">
				<img src="../../assets/imgs/mobile/icon_dianhua@2x.png" class="icon icon-phone">
				<input type="tel" placeholder="請輸入手機號碼" v-model="phone">
			</div>
			<div class="form-item">
				<img src="../../assets/imgs/mobile/icon_mima@2x.png" class="icon icon-password">
				<input type="password" placeholder="請輸入賬號密碼" v-model="password" @keyup="onKeyEnter">
			</div>
			<div class="btn-login" @click="doLogin"><div class="lds-ring" v-if="loading"><div></div><div></div><div></div><div></div></div>{{loading ? '正在登錄...' : '立即登录'}}</div>
			<router-link to="/forgetPassword" class="forget-password">忘記密碼？</router-link>
		</div>
	</div>
</template>

<script>
	import vHead from '@/layouts/MobileHead.vue'
	export default {
		data() {
			return {
				loading: false,
				isLogin: true,
				phone: '',
				password: '',
			}
		},
		components: {
			vHead
		},
		mounted: function() {
			if(this.$store.getters.userInfo) {
				this.$router.push({path: '/'});
			}
			if(this.$route.path == '/register') {
				this.isLogin = false
			}
		},
		methods: {
			onKeyEnter: function(k){
				if(k.key === 'Enter') {
					this.doLogin()
				}
			},
			doLogin: function(){
				if(this.loading) return

				if(! this.phone.trim()){
					this._toast.warning(this, '請輸入手機號')
					return
				}
				if(! /^1[3-9]{1}[0-9]{9}$/.test(this.phone.trim()) && ! /^([5|6|8|9])\d{7}$/.test(this.phone.trim())) {
					this._toast.warning(this, '手機號格式不正確')
					return
				}
				if(! this.password) {
					this._toast.warning(this, '請輸入密碼')
					return
				}

				this.loading = true
				this.$store.dispatch('login', {
                    loginName: this.phone,
                    password: this.password
                }).then(() => {
					this.loading = false
                    this.$router.push({path: '/'});
                }).catch((error) => {
					this.loading = false
					this._toast.warning(this, error || '登錄失敗, 請稍後重試！')
                })
			}
		}
	}
</script>

<style lang="scss" scoped>
	#login {
		width: 100%;
		height: 100%;
		padding-top: 83px;
		background-color: #fff;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.tabbar {
		display: flex;
		flex-direction: row;
		height: 54px;
		width: 260px;
		justify-content: space-between;
		align-items: center;
		margin-top: 91px;
		margin-bottom: 100px;
		position: relative;
	}
	
	.tabbar > a {
		display: inline-block;
		height: 54px;
		color: #231F20;
		font-weight: bold;
		font-size: 36px;
		line-height: 34px;
	}

	.tabbar > a.current {
		color: #36C4D0;
		position: relative;
	}

	.tabbar > a.current::after {
		content: '';
		position: absolute;
		left: 50%;
		bottom: 0;
		width: 14px;
		height: 6px;
		background-color: #36C4D0;
		transform: translateX(-50%);
	}

	.tabbar::after {
		content: '';
		position: absolute;
		left: 50%;
		top: 9px;
		transform: translateX(-50%);
		width: 1px;
		height: 16px;
		background-color: #DDDDDD;
	}
	
	.form {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.form > .form-item {
		display: flex;
		flex-direction: row;
		height: 88px;
		width: 540px;
		border-bottom: 1px solid #E8E8E8;
		align-items: center;
		font-weight: bold;
		color: #231F20;
		font-size: 28px;
	}

	.form > .form-item:hover {
		border-color: #7BD5DF;
	}

	.form > .form-item > input {
		color: #231F20;
		font-size: 28px;
		font-weight: bold;
	}
	
	.form > .form-item > input::placeholder {
		font-size: 24px;
		font-weight: 400;
		color: #969696;
	}

	.form > .form-item > .icon {
		width: 16px;
		height: 16px;
		margin: 0px 18px 0px 12px;
	}

	.form > .btn-login {
		box-shadow: 0px 10px 25px 0px #9EE3E8;
		width: 80%;
		height: 80px;
		color: #fff;
		font-size: 34px;
		font-weight: bold;
		border-radius: 40px;
		background-color: #36C4D0;
		margin-top: 210px;
		user-select: none;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.form > .btn-login:active {
		box-shadow: 0px 5px 15px 0px #9EE3E8;
	}

	.form > .forget-password {
		margin-top: 142px;
		font-weight: 400;
		font-size: 28px;
		color: #636363;
		line-height: 28px;
		text-decoration: underline;
	}

    /** loading start */
    .lds-ring {
        display: inline-block;
        position: relative;
        width: 64px;
        height: 64px;
    }
    
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 42px;
        height: 42px;
        margin: 8px;
        border: 8px solid #fff;
        border-radius: 50%;
        margin-top: 14px;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;
    }
    
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }

    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    /** loading end */
</style>